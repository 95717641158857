<template>
  <div class="highest">
    <div class="notice-container">
      <div class="notice-content">
        <div class="notice-content-inner d-flex df-col">
          <p class="notice-title-top">회사 합병에 따른</p>
          <p class="notice-title-bottom">개인정보 이전 안내</p>
          <div class="cursor notice-btn d-flex cursor" @click="moreDetail">
            <div class="notice-btn-text">자세히 보기 ></div>
          </div>
          <div class="d-flex df-row">
            <div class="spacer"></div>
            <img
              :src="require('@/assets/images/introduction/gp_merge.png')"
              class="notice-bg"
            />
          </div>
        </div>
      </div>
      <div class="notice-controller">
        <div class="notice-controller-inner d-flex df-row">
          <input type="checkbox" v-model="todayClose" class="notice-checkbox" />
          <label class="notice-checkbox-label cursor" @click="checkTodayClose">
            <p class="notice-checkbox-label-text">오늘 하루 열지 않기</p>
          </label>
          <div class="spacer"></div>
          <span class="notice-checkbox-label-text cursor" @click="closeNotice"
            >닫기</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonUtils from "@/utils/common.js";

export default {
  data() {
    return {
      todayClose: false
    };
  },
  methods: {
    checkTodayClose() {
      this.todayClose = !this.todayClose;
    },
    closeNotice() {
      if (this.todayClose) {
        const today = new Date();
        const expiryDate = new Date(today.setDate(today.getDate() + 1));

        commonUtils.setCookie("noticeClose", expiryDate);
        this.$emit("noticeClose");
      } else {
        this.$emit("noticeClose");
      }
    },
    moreDetail() {
      if (process.env.NODE_ENV === "production") {
        window.open(
          "https://help.qpicki.com/board-detail?id=86&boardId=3&dtPage=1"
        );
      } else {
        window.open(
          "https://release-help.qpicki.com/board-detail?id=85&boardId=3&dtPage=1"
        );
      }
      this.$emit("noticeClose");
    }
  }
};
</script>

<style lang="scss">
.highest {
  position: fixed;
  top: 118px;
  left: 17.5vw;
  right: 0;
  z-index: 5;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 1023px) {
    //top: 27vw;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
}
.notice-container {
  width: 20vw;
  height: 24.5vw;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.24));
  @media screen and (max-width: 1023px) {
    width: 87.4vw;
    height: 114vw;
    max-width: 360px;
    max-height: 470px;
    margin: calc(9vw + 56px) auto 0;
  }
}
.notice-content {
  width: 100%;
  height: 22vw;
  background: #54c7a2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @media screen and (max-width: 1023px) {
    width: 100%;
    height: 110vw;
    max-height: 420px;
    margin: auto;
  }
}
.notice-content-inner {
  padding: 3.125vw 1.56vw 1.35vw;
  @media screen and (max-width: 1023px) {
    padding: 60px 30px 26px;
  }
  @media screen and (max-width: 412px) {
    padding: 14.56vw 7.28vw 6.31vw;
  }
}
.notice-title-top {
  font-size: 1.04vw;
  font-weight: 400;
  line-height: 1.04vw;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  @media screen and (max-width: 1023px) {
    font-size: 20px;
    line-height: 20px;
  }
  @media screen and (max-width: 412px) {
    font-size: 4.85vw;
    line-height: 4.85vw;
  }
}
.notice-title-bottom {
  font-size: 1.56vw;
  font-weight: 700;
  line-height: 1.56vw;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0.625vw;
  color: #fff;
  @media screen and (max-width: 1023px) {
    font-size: 30px;
    line-height: 30px;
    margin-top: 20px;
  }
  @media screen and (max-width: 412px) {
    font-size: 7.28vw;
    line-height: 7.28vw;
    margin-top: 2.91vw;
  }
}
.notice-btn {
  width: 6.77vw;
  height: 1.66vw;
  background: #fff;
  border-radius: 3.125vw;
  margin: 1.04vw 0 0 0;
  @media screen and (max-width: 1023px) {
    width: 130px;
    height: 32px;
    border-radius: 60px;
    margin: 12px 0 0 0;
  }
  @media screen and (max-width: 412px) {
    width: 31.55vw;
    height: 7.76vw;
    border-radius: 14.56vw;
    margin: 4.85vw 0 0 0;
  }
}
.notice-btn-text {
  font-size: 0.73vw;
  font-weight: 400;
  line-height: 0.73vw;
  letter-spacing: 0em;
  text-align: left;
  color: #309574;
  margin: auto;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }
  @media screen and (max-width: 412px) {
    font-size: 3.4vw;
    line-height: 3.4vw;
  }
}
.notice-bg {
  width: 11.45vw;
  height: 11.45vw;
  @media screen and (max-width: 1023px) {
    width: 220px;
    height: 220px;
  }
  @media screen and (max-width: 412px) {
    width: 53.4vw;
    height: 53.4vw;
    padding-top: 6.31vw;
  }
}
.notice-controller {
  width: 100%;
  height: 2.6vw;
  background: rgba(255, 255, 255, 0.9);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  @media screen and (max-width: 1023px) {
    width: 100%;
    height: 12.13vw;
    max-height: 50px;
    margin: auto;
  }
}
.notice-controller-inner {
  padding: 0.67vw 0.88vw 0.67vw 1.04vw;
  max-height: 2.6vw;
  box-sizing: border-box;
  @media screen and (max-width: 1023px) {
    padding: 13px 17px 13px 20px;
    max-height: 50px;
  }
  @media screen and (max-width: 412px) {
    padding: 3.15vw 4.12vw 3.15vw 4.85vw;
    max-height: 12.13vw;
  }
}
.notice-checkbox {
  visibility: hidden;
  margin-right: -1.25vw;
  @media screen and (max-width: 1023px) {
    margin-right: -24px;
  }
  @media screen and (max-width: 412px) {
    margin-right: -5.82vw;
  }
}
.notice-checkbox ~ .notice-checkbox-label::before {
  position: relative;
  display: inline-block;
  content: "";
  width: 1.25vw;
  height: 1.25vw;
  margin-right: 0.41vw;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 1.25vw auto;
  background-image: url("~@/assets/images/common/bg_checkbox.svg");
  @media screen and (max-width: 1023px) {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-size: 24px auto;
  }
  @media screen and (max-width: 412px) {
    width: 5.82vw;
    height: 5.82vw;
    margin-right: 1.94vw;
    background-size: 5.82vw auto;
  }
}
.notice-checkbox:checked ~ .notice-checkbox-label::before {
  background-image: url("~@/assets/images/common/bg_checkbox_active.svg");
}
.notice-checkbox-label {
  margin: auto;
  display: inline-flex;
}
.notice-checkbox-label-text {
  position: relative;
  font-size: 0.73vw;
  font-weight: 400;
  line-height: 0.73vw;
  letter-spacing: -0.005em;
  text-align: left;
  color: #797979;
  margin: auto;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 14px;
  }
  @media screen and (max-width: 412px) {
    font-size: 3.4vw;
    line-height: 3.4vw;
  }
}
</style>
