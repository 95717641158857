<template>
  <div class="thick-dim" @click.self="close">
    <div class="fd-popup-container d-flex df-row">
      <div class="fd-popup-wrapper d-flex df-col">
        <!--모바일 닫기버튼 -->
        <div class="d-flex df-row" v-if="width <= 1023">
          <div class="spacer"></div>
          <img
            :src="require('@/assets/images/introduction/btn_close_white.svg')"
            @click.self="close"
          />
        </div>
        <div class="spacer"></div>
        <!--데스크탑 닫기버튼 -->
        <img
          v-if="width > 1023"
          class="btn-close-desktop"
          :src="require('@/assets/images/introduction/btn_close_white.svg')"
          @click.self="close"
        />
        <!--컨텐츠 영역 -->
        <div class="fd-content-wrapper d-flex df-col">
          <h3 class="head-title">나만의 테마 편집을 시작하세요.</h3>
          <p class="sub-title">
            테마는 쇼핑몰 솔루션을 사용하지 않아도
            <br v-if="width < 412" />
            편집이 가능합니다.
          </p>
          <div class="input-group d-flex df-col">
            <!-- 솔루션 정보 -->
            <div
              class="input-common solution-input d-flex"
              :class="solution.class"
            >
              <img
                :src="require('@/assets/images/introduction/' + solution.svg)"
                class="solution-logo"
              />
            </div>
            <!-- 사이트 선택 -->
            <div
              v-show="siteSelectState"
              class="
                input-between input-common input-box
                theme-selector
                cursor
                d-flex
                df-row
              "
              :class="[
                $route.query.type === 'theme'
                  ? 'theme-selector-impossible'
                  : 'theme-selector-possible'
              ]"
              ref="site"
              @click="siteFloatingInfo"
            >
              <p>{{ site.name }}</p>
              <div class="spacer"></div>
              <img
                :src="
                  require('@/assets/images/introduction/ico_down_selector.svg')
                "
              />
            </div>
            <!-- 경고메세지 -->
            <div
              class="d-flex df-row message-area"
              v-show="siteSelectState && msgState"
            >
              <img
                :src="require('@/assets/images/introduction/ico_error.svg')"
                class="ico-error"
              />
              <p class="message-text">
                {{ message }}
              </p>
            </div>
            <!-- 사이트명 -->
            <div class="input-between input-box" v-show="siteNameState">
              <input
                type="text"
                class="input-common"
                placeholder="사이트명"
                name="site-name"
                maxlength="20"
                v-model="siteName"
              />
              <label for="site-name">사이트명</label>
            </div>
            <!-- 테마명 -->
            <div class="input-between input-box" v-show="themeNameState">
              <input
                type="text"
                class="input-common"
                placeholder="테마명"
                name="theme-name"
                maxlength="20"
                v-model="themeName"
              />
              <label for="theme-name">테마명</label>
            </div>
            <!-- 테마생성 버튼 -->
            <div
              v-show="createBtnState"
              class="input-between input-common btn-theme-create d-flex cursor"
              :class="{'btn-active': btnActive}"
              @click="beforeThemeCreate"
            >
              <p class="btn-theme-create-text">테마 만들기</p>
            </div>
          </div>
        </div>
        <div class="spacer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleUserSite = createNamespacedHelpers("ModuleUserSite");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
export default {
  data() {
    return {
      width: 0,
      siteSelectState: true,
      siteNameState: false,
      themeNameState: false,
      createBtnState: false,
      solution: {
        id: 3,
        name: "마이소호",
        class: "mysoho-input",
        svg: "ico_mysoho.svg"
      },
      solutions: [
        // {
        //   id: 2,
        //   name: "메이크샵",
        //   class: "makeshop-input",
        //   svg: "ico_makeshop.svg"
        // },
        {
          id: 3,
          name: "마이소호",
          class: "mysoho-input",
          svg: "ico_mysoho.svg"
        }
      ],
      site: {
        id: -1,
        name: "선택하세요.",
        clickType: "temp"
      },
      sites: [
        {
          id: -1,
          name: "선택하세요.",
          clickType: "temp"
        },
        {
          id: 0,
          name: "사이트 신규생성",
          clickType: "temp"
        }
      ],
      themeInfo: {},
      siteCount: 0,
      themeCount: 0,
      siteName: "",
      themeName: "",
      message: "",
      msgState: false
    };
  },
  computed: {
    checkSolution() {
      return this.$route.query.id;
    },
    btnActive() {
      return this.$route.query.type === "site" &&
        this.siteName &&
        this.themeName
        ? true
        : this.$route.query.type === "theme" && this.themeName
        ? true
        : (this.site.id > 0 && this.themeName) ||
          (this.site.id === 0 && this.siteName && this.themeName)
        ? true
        : false;
    },
    ...ModuleIntroCommon.mapGetters([
      "getFullDimShowState",
      "getFullDimData",
      "getTemp"
    ]),
    ...ModuleAuth.mapGetters(["getLoggedData", "getLoginStatus"]),
    ...ModuleUserSite.mapGetters(["getUserSiteAddData"]),
    ...ModuleTheme.mapGetters(["getThemeCatalogData"])
  },
  watch: {
    getFullDimShowState(newVal) {
      if (newVal && this.getLoginStatus) {
        this.dataSetting();
        this.resetState();
        this.userTheme();
      }
      if (!newVal) {
        this.resetData();
        this.message = "";
        this.setFullDimData(null);
      }
    },
    //사이트 선택값 변경시
    getTemp(newVal) {
      if (newVal) {
        this.site = newVal;
        this.message = "";
        this.msgState = false;

        if (this.site.id === 0) {
          //신규 사이트 생성 선택시
          if (this.siteCount >= 1) {
            this.message = "베타 오픈 기간에는 사이트 생성이 1개로 제한됩니다.";
            this.msgState = true;
            this.siteNameState = false;
            this.themeNameState = false;
            this.createBtnState = false;
          } else {
            this.siteNameState = true;
            this.themeNameState = true;
            this.createBtnState = true;
          }
        } else if (this.site.id > 0) {
          //기존 사이트 항목 선택시
          if (this.themeCount >= 2) {
            this.message = "베타 오픈 기간에는 테마 생성이 2개로 제한됩니다.";
            this.msgState = true;
            this.themeNameState = false;
            this.createBtnState = false;
          } else {
            this.themeNameState = true;
            this.createBtnState = true;
          }
        } else {
          this.resetState();
        }
      }
    }
  },
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--height", `${vh}px`);
    window.addEventListener("resize", this.resizeHeight);

    window.addEventListener("resize", this.handleResize);
    this.width = window.innerWidth;

    this.dataSetting();
    this.resetState();
    if (this.getLoginStatus) this.userTheme();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.resizeHeight);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    resizeHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--height", `${vh}px`);
    },
    close() {
      this.setFullDimShowState(false);
    },
    //상태값 초기화 함수
    resetState() {
      //어드민에서 진입시 쿼리스트링 체크(type)
      if (!this.$route.query.type) {
        this.siteSelectState = true;
        this.siteNameState = false;
        this.themeNameState = false;
        this.createBtnState = false;
      } else {
        //어드민에서 진입시
        this.siteSelectState =
          this.$route.query.type === "theme" ? true : false;
        this.siteNameState = this.$route.query.type === "site" ? true : false;
        this.themeNameState = true;
        this.themeName = this.themeInfo.name;
        this.createBtnState = true;
      }
    },
    //데이터 초기화 함수
    resetData() {
      this.msgState = false;
      this.message = "";

      this.site = {
        id: -1,
        name: "선택하세요.",
        clickType: "temp"
      };
      this.sites = [
        {
          id: -1,
          name: "선택하세요.",
          clickType: "temp"
        },
        {
          id: 0,
          name: "사이트 신규생성",
          clickType: "temp"
        }
      ];
    },
    //데이터 셋팅
    dataSetting() {
      //솔루션 셋팅
      this.solutions.forEach(item => {
        if (item.id === this.$route.query.id) {
          this.solution = item;
        }
      });
      //테마정보 추출
      this.searchTheme();
    },
    //사이트 선택 플로팅 팝업
    siteFloatingInfo() {
      if (this.$route.query.type === "theme") return false;

      const target = this.$refs.site;
      const {bottom, left, width} = target.getBoundingClientRect();

      let x = bottom;
      let y = left;

      this.setFloatingPosition({
        top: x + "px",
        left: y + "px",
        width: width + "px"
      });

      this.setFloatingData(this.sites);
      this.setFloatingType("select");
      this.setFloatingShowState(true);
    },
    //테마생성 유효성검사
    beforeThemeCreate() {
      //어드민 진입 - 사이트 생성
      if (this.$route.query.type === "site") {
        if (!this.siteName || !this.themeName) return false;
        this.addSiteInfo();
      }
      //어드민 진입 - 테마 생성
      else if (this.$route.query.type === "theme") {
        if (!this.themeName) return false;
        this.themeCreate();
      }
      //서비스 소개 진입
      else {
        //기존 사이트 - 테마 생성
        if (this.site.id > 0 && this.themeName) this.themeCreate();
        //신규 사이트 - 사이트 생성
        else if (this.site.id === 0 && this.siteName && this.themeName)
          this.addSiteInfo();
        else return false;
      }
    },
    //회원 테마 통합생성
    themeCreate() {
      let payload = {};
      payload.userSite =
        this.site.id > 0 ? this.site.id : this.getUserSiteAddData.id;
      payload.themeName = this.themeName;
      payload.themeId = this.getFullDimData;

      this.actUserThemeIntegrateCreate(payload).then(response => {
        switch (response.status) {
          case 200:
            this.$store.dispatch("ModuleUser/actUserLog", {
              eventName: "테마 추가",
              requestLocation: "큐픽 > 어드민 > 테마관리",
              solutionName: response.data.theme.solutionName,
              themeId: response.data.theme.id
            });
            //어드민 테마관리 페이지 이동
            this.$router.push("/admin/theme/" + payload.userSite);
            this.setFullDimShowState(false);
            break;

          default:
            break;
        }
      });
    },
    //사이트 정보 목록 조회
    userSiteList() {
      let payload = {};
      payload.solutionId = this.solution.id;
      payload.userId = this.getLoggedData.id;

      this.actUserSiteList(payload).then(response => {
        const siteList = response.data?.list;
        switch (response.status) {
          case 200:
            this.siteCount = siteList.length;

            if (siteList.length > 0) {
              siteList.forEach(item => {
                //API 중복요청으로 인해 동일 사이트 2번 들어가는 이슈 방어로직
                let state = true;
                this.sites.forEach(site => {
                  if (site.id === item.id) {
                    state = false;
                  }
                });

                if (state) {
                  item.clickType = "temp";
                  this.sites.push(item);
                }

                //어드민 테마생성 진입시 선택한 사이트값 셋팅
                if (this.$route.query.type === "theme") {
                  const siteId = this.$route.query.siteId;
                  if (item.id === Number(siteId)) {
                    this.site = item;
                  }
                  //테마생성 이미 2개이상일 때
                  if (this.themeCount >= 2) {
                    this.message =
                      "베타 오픈 기간에는 테마 생성이 2개로 제한됩니다.";
                    this.msgState = true;
                    this.themeNameState = false;
                    this.createBtnState = false;
                  }
                }
              });
            }
            break;

          default:
            break;
        }
      });
    },
    //사이트 정보 추가
    addSiteInfo() {
      let payload = {};
      payload.solutionId = this.solution.id;
      payload.name = this.siteName;
      payload.userId = this.getLoggedData.id;

      this.actUserSiteAdd(payload).then(response => {
        switch (response.status) {
          case 201:
            this.$store.dispatch("ModuleUser/actUserLog", {
              eventName: "사이트 추가",
              requestLocation: "큐픽 > 어드민",
              solutionName: response.data.solution.name,
              siteId: response.data.id
            });
            this.themeCreate();
            break;

          default:
            break;
        }
      });
    },
    //회원 테마목록 조회
    userTheme() {
      this.actUserThemeList({}).then(response => {
        switch (response.status) {
          case 200:
            this.themeCount = response.data.length;
            this.userSiteList();
            break;

          default:
            break;
        }
      });
    },
    //테마 목록 조회
    searchTheme() {
      let payload = {};
      payload.solutionId = this.solution.id;

      this.actThemeCatalog(payload).then(response => {
        switch (response.status) {
          case 200:
            //선택한 테마정보 추출
            response.data.forEach(item => {
              if (item.id === this.getFullDimData) {
                this.themeInfo = item.product;
                this.themeName = this.themeInfo.name;
              }
            });
            break;

          default:
            break;
        }
      });
    },
    ...ModuleIntroCommon.mapMutations([
      "setFullDimShowState",
      "setFloatingShowState",
      "setFloatingData",
      "setFloatingType",
      "setFloatingPosition",
      "setFullDimData"
    ]),
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleUserSite.mapActions(["actUserSiteList", "actUserSiteAdd"]),
    ...ModuleTheme.mapActions([
      "actUserThemeIntegrateCreate",
      "actUserThemeList",
      "actThemeCatalog"
    ])
  }
};
</script>

<style lang="scss" scoped>
.thick-dim {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  max-height: calc(var(--height, 1vh) * 100);
  overflow-y: auto;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 3;
}
.fd-popup-container {
  width: 100%;
}
.head-title {
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  letter-spacing: -0.05em;
}
.sub-title {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  margin: 69px 0 0 0;
  font-weight: 200;
  white-space: nowrap;
  letter-spacing: -0.05em;
}
.input-group {
  margin: 32px auto 0;
  width: 100%;
  max-width: 340px;
}
.input-between {
  margin: 24px 0 0 0;
}
.input-common {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  box-sizing: border-box;
}
.solution-input {
  background: rgba(255, 255, 255, 0.6);
}
.solution-logo {
  margin: auto;
}
.makeshop-input {
  border: 1px solid #1f58dd;
}
.mysoho-input {
  border: 1px solid #00affe;
}
.theme-selector {
  border: none;
  padding: 16px;
  font-size: 16px;
  background: #272727;
}
.theme-selector-possible {
  color: #d8dae5;
  border: solid 1px #d8dae5;
  box-sizing: border-box;
}
.theme-selector-impossible {
  color: rgba(216, 218, 229, 0.5);
  border: solid 1px rgba(216, 218, 229, 0.5);
  box-sizing: border-box;
}
.input-box {
  position: relative;
  width: 100%;
}
.input-box > input {
  border: none;
  border: solid 1px #d8dae5;
  padding: 16px;
  font-size: 16px;
  color: #d8dae5;
  background: #272727;
}
input::placeholder {
  color: transparent;
}
input:placeholder-shown + label {
  color: #d8dae5;
  font-size: 16px;
  top: 15px;
  background: #272727;
}
input:focus + label,
label {
  color: #d8dae5;
  font-size: 12px;
  pointer-events: none;
  position: absolute;
  left: 15px;
  top: -10px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  background: #272727;
}
input:focus,
input:not(:placeholder-shown) {
  border: solid 1px #d8dae5;
  outline: none;
}
.btn-theme-create {
  background: #dcdcdc;
}
.btn-active {
  background: #54c7a2;
}
.btn-theme-create-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #fff;
  margin: auto;
}
.message-area {
  margin: 8px 0 0 0;
  align-items: baseline;
}
.ico-error {
  margin: 1px 4px auto 0;
  align-items: flex-start;
  width: 14px;
  height: 14px;
}
.message-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.005em;
  color: #d14343;
}
.fd-popup-wrapper {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  @media screen and (min-width: 1024px) {
    width: 100%;
    margin: 0 auto;
  }
}
.fd-content-wrapper {
  padding: 0 19px;
  box-sizing: border-box;
  @media screen and (min-width: 1024px) {
    max-width: 35%;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
.btn-close-desktop {
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 69px;
    right: 280px;
  }
}
</style>
