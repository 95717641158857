var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "highest"
  }, [_c('div', {
    staticClass: "notice-container"
  }, [_c('div', {
    staticClass: "notice-content"
  }, [_c('div', {
    staticClass: "notice-content-inner d-flex df-col"
  }, [_c('p', {
    staticClass: "notice-title-top"
  }, [_vm._v("회사 합병에 따른")]), _c('p', {
    staticClass: "notice-title-bottom"
  }, [_vm._v("개인정보 이전 안내")]), _c('div', {
    staticClass: "cursor notice-btn d-flex cursor",
    on: {
      "click": _vm.moreDetail
    }
  }, [_c('div', {
    staticClass: "notice-btn-text"
  }, [_vm._v("자세히 보기 >")])]), _c('div', {
    staticClass: "d-flex df-row"
  }, [_c('div', {
    staticClass: "spacer"
  }), _c('img', {
    staticClass: "notice-bg",
    attrs: {
      "src": require('@/assets/images/introduction/gp_merge.png')
    }
  })])])]), _c('div', {
    staticClass: "notice-controller"
  }, [_c('div', {
    staticClass: "notice-controller-inner d-flex df-row"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.todayClose,
      expression: "todayClose"
    }],
    staticClass: "notice-checkbox",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.todayClose) ? _vm._i(_vm.todayClose, null) > -1 : _vm.todayClose
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.todayClose,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.todayClose = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.todayClose = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.todayClose = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "notice-checkbox-label cursor",
    on: {
      "click": _vm.checkTodayClose
    }
  }, [_c('p', {
    staticClass: "notice-checkbox-label-text"
  }, [_vm._v("오늘 하루 열지 않기")])]), _c('div', {
    staticClass: "spacer"
  }), _c('span', {
    staticClass: "notice-checkbox-label-text cursor",
    on: {
      "click": _vm.closeNotice
    }
  }, [_vm._v("닫기")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }