var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "thick-dim",
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "fd-popup-container d-flex df-row"
  }, [_c('div', {
    staticClass: "fd-popup-wrapper d-flex df-col"
  }, [_vm.width <= 1023 ? _c('div', {
    staticClass: "d-flex df-row"
  }, [_c('div', {
    staticClass: "spacer"
  }), _c('img', {
    attrs: {
      "src": require('@/assets/images/introduction/btn_close_white.svg')
    },
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.close.apply(null, arguments);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "spacer"
  }), _vm.width > 1023 ? _c('img', {
    staticClass: "btn-close-desktop",
    attrs: {
      "src": require('@/assets/images/introduction/btn_close_white.svg')
    },
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.close.apply(null, arguments);
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "fd-content-wrapper d-flex df-col"
  }, [_c('h3', {
    staticClass: "head-title"
  }, [_vm._v("나만의 테마 편집을 시작하세요.")]), _c('p', {
    staticClass: "sub-title"
  }, [_vm._v(" 테마는 쇼핑몰 솔루션을 사용하지 않아도 "), _vm.width < 412 ? _c('br') : _vm._e(), _vm._v(" 편집이 가능합니다. ")]), _c('div', {
    staticClass: "input-group d-flex df-col"
  }, [_c('div', {
    staticClass: "input-common solution-input d-flex",
    class: _vm.solution.class
  }, [_c('img', {
    staticClass: "solution-logo",
    attrs: {
      "src": require('@/assets/images/introduction/' + _vm.solution.svg)
    }
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.siteSelectState,
      expression: "siteSelectState"
    }],
    ref: "site",
    staticClass: "\n              input-between input-common input-box\n              theme-selector\n              cursor\n              d-flex\n              df-row\n            ",
    class: [_vm.$route.query.type === 'theme' ? 'theme-selector-impossible' : 'theme-selector-possible'],
    on: {
      "click": _vm.siteFloatingInfo
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.site.name))]), _c('div', {
    staticClass: "spacer"
  }), _c('img', {
    attrs: {
      "src": require('@/assets/images/introduction/ico_down_selector.svg')
    }
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.siteSelectState && _vm.msgState,
      expression: "siteSelectState && msgState"
    }],
    staticClass: "d-flex df-row message-area"
  }, [_c('img', {
    staticClass: "ico-error",
    attrs: {
      "src": require('@/assets/images/introduction/ico_error.svg')
    }
  }), _c('p', {
    staticClass: "message-text"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.siteNameState,
      expression: "siteNameState"
    }],
    staticClass: "input-between input-box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.siteName,
      expression: "siteName"
    }],
    staticClass: "input-common",
    attrs: {
      "type": "text",
      "placeholder": "사이트명",
      "name": "site-name",
      "maxlength": "20"
    },
    domProps: {
      "value": _vm.siteName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.siteName = $event.target.value;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "site-name"
    }
  }, [_vm._v("사이트명")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.themeNameState,
      expression: "themeNameState"
    }],
    staticClass: "input-between input-box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.themeName,
      expression: "themeName"
    }],
    staticClass: "input-common",
    attrs: {
      "type": "text",
      "placeholder": "테마명",
      "name": "theme-name",
      "maxlength": "20"
    },
    domProps: {
      "value": _vm.themeName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.themeName = $event.target.value;
      }
    }
  }), _c('label', {
    attrs: {
      "for": "theme-name"
    }
  }, [_vm._v("테마명")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.createBtnState,
      expression: "createBtnState"
    }],
    staticClass: "input-between input-common btn-theme-create d-flex cursor",
    class: {
      'btn-active': _vm.btnActive
    },
    on: {
      "click": _vm.beforeThemeCreate
    }
  }, [_c('p', {
    staticClass: "btn-theme-create-text"
  }, [_vm._v("테마 만들기")])])])]), _c('div', {
    staticClass: "spacer"
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }