<template>
  <div>
    <iheader @movePage="movePage" />
    <router-view @movePage="movePage" />
    <ifooter @movePage="movePage" />
    <main-notice-popup v-show="noticeState" @noticeClose="noticeClose" />
    <div class="dim" @click.self="close" v-show="mobileSlideState">
      <transition name="slide">
        <expanded-menu v-show="getExpandedShowState" @close="close" />
      </transition>
    </div>
    <full-dim-popup v-show="getFullDimShowState" />
    <floating-menu v-show="getFloatingShowState" />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {getAuthToken, removeAuthToken} from "@/utils/auth";
import channelService from "@/utils/channel.js";
import commonUtils from "@/utils/common.js";
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");

import floatingMenu from "@/components/introduction/floating-menu.vue";
import expandedMenu from "@/components/introduction/expanded-menu.vue";
import fullDimPopup from "@/components/introduction/full-dim-popup.vue";
import mainNoticePopup from "@/components/introduction/main-notice-popup.vue";

export default {
  data() {
    return {
      width: 0,
      previewMode: false,
      mobileSlideState: false,
      noticeState: false
    };
  },
  computed: {
    noticeCheck() {
      return this.noticeState;
    },
    ...ModuleIntroCommon.mapGetters([
      "getFloatingShowState",
      "getFloatingData",
      "getFloatingPosition",
      "getFloatingType",
      "getExpandedShowState",
      "getExpandedData",
      "getFullDimShowState"
    ]),
    ...ModuleAuth.mapGetters(["getLoginStatus", "getLoggedData"])
  },
  watch: {
    noticeCheck(newVal) {
      if (newVal) {
        this.width < 1024 ? this.scrollLock() : this.scrollUnlock();
      } else {
        this.scrollUnlock();
      }
    },
    getFullDimShowState(newVal) {
      newVal ? this.scrollLock() : this.scrollUnlock();
    },
    getExpandedShowState(newVal) {
      if (newVal) {
        this.mobileSlideState = true;
        this.scrollLock();
      } else {
        setTimeout(() => {
          this.mobileSlideState = false;
        }, 500);
        this.scrollUnlock();
      }
    },
    // 패스 변경 감지
    $route() {
      if (this.$route.path === "/introduction/preview") {
        this.previewMode = true;
        this.noticeState = false;
        channelService.shutdown();
      } else {
        this.previewMode = false;
        this.noticeState = false;

        if (this.$route.path === "/introduction/main") {
          this.notice();
        }
      }
    },
    getLoginStatus(newVal) {
      if (!newVal) {
        this.channelTalk(false);
      }
    }
  },
  created() {
    if (this.$route.path !== "/introduction/preview")
      channelService.constructor();

    //새로고침에 의해 새로 렌더링될 시, 프리뷰 모드 지정
    this.previewMode =
      this.$route.path === "/introduction/preview" ? true : false;

    //테마생성 레이어팝업이 열린채 탭을 끄거나 새로고침시 한 경우 방지
    if (this.getFullDimShowState) {
      this.setFullDimShowState(false);
    }

    //공지팝업 노출여부 계산
    if (this.$route.path === "/introduction/main") {
      this.notice();
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrolling);
    window.addEventListener("resize", this.handleResize);
    this.width = window.innerWidth;

    //통합로그인 성공 후 쿠키 유무에 따라 UI변경
    let authToken = getAuthToken();
    if (authToken) {
      this.actLogged()
        .then(response => {
          switch (response.status) {
            case 200:
              if (
                response.data.emailVerifiedAt === null &&
                response.data.grade === 1
              ) {
                this.$router.push(
                  `/accounts/signup/certify/notice?email=${response.data.privacy.email}`
                );
              }
              this.setRedirectPage(null);
              this.setLoginStatus(true);
              if (this.$route.path !== "/introduction/preview")
                this.channelTalk(true);
              break;

            default:
              removeAuthToken();
              this.setLoginStatus(false);
              break;
          }
        })
        .catch(error => {
          console.log(error);
          removeAuthToken();
        });
    } else {
      //로그인 유저정보 초기화
      this.setLogged({});
      this.setLoginStatus(false);
      removeAuthToken();
      if (this.$route.path !== "/introduction/preview") this.channelTalk(false);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  destroyed() {
    if (this.$route.path !== "/introduction/preview") channelService.shutdown();
  },
  methods: {
    // 페이지 이동
    movePage(path) {
      if (path === "/accounts/login") {
        let targetPage =
          location.protocol + "//" + location.host + "/admin/dashboard";
        this.$router.push(path + "?callback=" + encodeURIComponent(targetPage));
        return;
      } else if (path === "/accounts/signup") {
        let targetPage = this.getRedirectPage || document.URL;
        this.$router.push(path + "?callback=" + encodeURIComponent(targetPage));
        return;
      } else if (path === "mysoho") {
        window.open("https://www.mysoho.com/");
      } else if (path === "interlock-mysoho") {
        window.open(
          "https://help.qpicki.com/board-detail?id=25&boardId=2&dtPage=1"
        );
      } else {
        this.$router.push(path);
      }
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    scrolling() {
      if (this.getFloatingType !== "userInfo") {
        this.setFloatingShowState(false);
        this.setFloatingPosition({});
        this.setFloatingData([]);
        this.setFloatingType("userInfo");
      }
    },
    //팝업 부모창 스크롤 막기
    scrollLock() {
      const body = document.querySelector("body");
      body.style.overflow = "hidden";
      body.style.height = "100%";
      body.style.touchAction = "none";
    },
    //팝업 부모창 스크롤 막기 해제
    scrollUnlock() {
      const body = document.querySelector("body");
      body.style.removeProperty("overflow");
      body.style.removeProperty("height");
      body.style.removeProperty("touch-action");
    },
    //채널톡 실행
    channelTalk(isLogin) {
      let settings = {};
      let profile = {};

      if (isLogin) {
        settings.pluginKey = process.env.VUE_APP_AUTH_CHANNEL_TALK_PLUGIN_KEY;
        settings.member = true;
        profile.name = this.getLoggedData.privacy.name;
        profile.joinTime = this.getLoggedData.createdAt;
        profile.qpickId = this.getLoggedData.privacy.email;
        settings.memberId = this.getLoggedData.id;
        settings.profile = profile;

        channelService.boot(settings);
      } else {
        settings.pluginKey = process.env.VUE_APP_AUTH_CHANNEL_TALK_PLUGIN_KEY;
        settings.member = false;

        channelService.boot(settings);
      }
    },
    close() {
      this.setExpandedShowState(false);
      this.setExpandedData([]);
    },
    noticeClose() {
      this.noticeState = false;
      this.scrollUnlock();
    },
    //공지팝업 날짜 계산 및 노출
    notice() {
      const now = new Date();
      const savedExpiryDate = new Date(commonUtils.getCookie("noticeClose"));
      let noticeEnd = new Date("2022-12-01 00:00");

      if (now >= noticeEnd) {
        this.noticeState = false;
      } else if (!savedExpiryDate) {
        this.noticeState = true;
        if (this.width < 1024) this.scrollLock();
      } else {
        const year = now.getFullYear();
        const month = now.getMonth();
        const day = now.getDate();

        const expiryYear = savedExpiryDate.getFullYear();
        const expiryMonth = savedExpiryDate.getMonth();
        const expiryDay = savedExpiryDate.getDate();

        const today = new Date(year, month, day);
        const expiryDate = new Date(expiryYear, expiryMonth, expiryDay);

        const btMs = today.getTime() - expiryDate.getTime();
        const btDays = btMs / (1000 * 60 * 60 * 24);

        if (btDays < 0) {
          this.noticeState = false;
          this.scrollUnlock();
        } else {
          commonUtils.deleteCookie("noticeClose");
          this.noticeState = true;
          if (this.width < 1024) this.scrollLock();
        }
      }
    },
    ...ModuleAuth.mapActions(["actLogged"]),
    ...ModuleAuth.mapMutations(["setLogged", "setLoginStatus"]),
    ...ModuleIntroCommon.mapMutations([
      "setRedirectPage",
      "setExpandedShowState",
      "setExpandedData",
      "setFullDimShowState",
      "setFloatingShowState",
      "setFloatingData",
      "setFloatingType",
      "setFloatingPosition"
    ])
  },
  components: {
    iheader: () => import("@/components/introduction/intro-header"),
    ifooter: () => import("@/components/introduction/intro-footer"),
    floatingMenu,
    expandedMenu,
    fullDimPopup,
    mainNoticePopup
  }
};
</script>

<style lang="scss" scoped></style>
