<template>
  <!--  <div class="dim" @click.self="close">-->
  <div class="expanded-container d-flex df-col">
    <div class="expanded-wrapper">
      <!-- 상단: 고정영역 -->
      <div class="expanded-top">
        <!-- 유저정보 -->
        <div class="d-flex df-row">
          <div
            v-if="loginStatus && getLoggedData.grade !== 0"
            class="d-flex df-row"
          >
            <img
              :src="
                require('@/assets/images/introduction/ico_regular_user.svg')
              "
              class="cursor btn-24 user-icon-mar"
            />
            <p class="user-info-text ellipsis">
              {{ getLoggedData.privacy?.email }}
            </p>
          </div>
          <div
            v-else-if="loginStatus && getLoggedData.grade === 0"
            class="d-flex df-row"
          >
            <img
              :src="
                require('@/assets/images/introduction/ico_associate_user.svg')
              "
              class="cursor btn-24 user-icon-mar"
            />
            <p class="user-info-text ellipsis">
              {{ "준회원(" + getLoggedData.id + ")" }}
            </p>
          </div>
          <div class="spacer"></div>
          <img
            :src="require('@/assets/images/introduction/btn_close.svg')"
            class="cursor btn-24"
            @click="close"
          />
        </div>
        <!-- 버튼 -->
        <div class="d-flex df-col button-group">
          <div v-if="loginStatus">
            <div
              class="btn-common btn-admin d-flex cursor"
              @click="movePage('/admin/dashboard')"
            >
              <p class="exp-text-common centered">나의 테마관리</p>
            </div>
            <div class="d-flex df-row">
              <div
                class="btn-common btn-half-left d-flex cursor"
                @click="
                  movePage(
                    getLoggedData.grade === 0
                      ? '/accounts/gradeup'
                      : '/accounts/personal'
                  )
                "
              >
                <p
                  v-if="getLoggedData.grade === 0"
                  class="exp-text-common btn-function-text centered"
                >
                  회원전환
                </p>
                <p
                  v-else
                  class="exp-text-common centered cursor btn-function-text"
                >
                  계정정보
                </p>
              </div>
              <div
                class="btn-common btn-half-right d-flex cursor"
                @click="userLogout"
              >
                <p class="exp-text-common btn-function-text">로그아웃</p>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="btn-common btn-start d-flex cursor"
              @click="movePage('/accounts/signup')"
            >
              <p class="exp-text-common centered">무료 시작하기</p>
            </div>
            <div
              class="btn-common btn-login d-flex cursor"
              @click="beforeLoginPageLoad('/accounts/login')"
            >
              <p class="exp-text-common centered btn-login-text">로그인</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 하단: 스크롤영역 -->
      <div class="expanded-bottom">
        <div class="d-flex df-col">
          <!-- 메뉴 -->
          <expanded-drawer :data="getExpandedData" @close="close" />
        </div>
      </div>
    </div>
  </div>
  <!--  </div>-->
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {getAuthToken, removeAuthToken} from "@/utils/auth";
import channelService from "@/utils/channel.js";
import expandedDrawer from "@/components/introduction/expanded-drawer.vue";

const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  computed: {
    loginStatus() {
      return this.getLoginStatus;
    },
    ...ModuleAuth.mapGetters(["getLoggedData", "getLoginStatus"]),
    ...ModuleIntroCommon.mapGetters(["getRedirectPage", "getExpandedData"])
  },
  methods: {
    // 로그아웃
    userLogout() {
      this.actLogout().then(() => {
        removeAuthToken();
        localStorage.removeItem("userId");
        this.setLogged({});
        this.setLoginStatus(false);
        this.close();

        //테마목록 페이지 내 로그아웃시 쿼리 스트링 삭제 (어드민 진입 상태값 삭제)
        if (this.$route.path === "/introduction/theme-list") {
          this.$router.replace({
            query: {
              id: this.$route.query.id
            }
          });
        }
      });
    },
    beforeLoginPageLoad(path) {
      //다른 큐픽 사이트에서 로그인을 한 경우
      let authToken = getAuthToken();
      if (authToken) {
        this.actLogged()
          .then(response => {
            switch (response.status) {
              case 200:
                this.setLoginStatus(true);
                this.setRedirectPage(null);
                this.channelTalk(true);
                break;

              case 401:
              default:
                removeAuthToken();
                this.setLoginStatus(false);
                break;
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.movePage(path);
      }
    },
    //페이지 이동
    movePage(path) {
      if (path === "/accounts/login" || path === "/accounts/signup") {
        this.close();
        //로그인 페이지
        let targetPage = this.getRedirectPage || document.URL;
        this.$router.push(path + "?callback=" + encodeURIComponent(targetPage));
        return;
      } else if (
        path === "/accounts/gradeup" ||
        path === "/accounts/personal"
      ) {
        let routeData = this.$router.resolve({
          path: path,
          query: {
            callback: document.URL
          }
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push(path);
      }
      this.close();
    },
    //채널톡 실행
    channelTalk(isLogin) {
      let settings = {};
      let profile = {};

      if (isLogin) {
        settings.pluginKey = process.env.VUE_APP_AUTH_CHANNEL_TALK_PLUGIN_KEY;
        settings.member = true;
        profile.name = this.getLoggedData.privacy.name;
        profile.joinTime = this.getLoggedData.createdAt;
        profile.qpickId = this.getLoggedData.privacy.email;
        settings.memberId = this.getLoggedData.id;
        settings.profile = profile;

        channelService.boot(settings);
      } else {
        settings.pluginKey = process.env.VUE_APP_AUTH_CHANNEL_TALK_PLUGIN_KEY;
        settings.member = false;

        channelService.boot(settings);
      }
    },
    close() {
      this.$emit("close");
    },
    ...ModuleAuth.mapActions(["actLogout", "actLogged"]),
    ...ModuleAuth.mapMutations(["setLogged", "setLoginStatus"]),
    ...ModuleIntroCommon.mapMutations([
      "setExpandedShowState",
      "setExpandedData",
      "setRedirectPage"
    ])
  },
  components: {
    expandedDrawer
  }
};
</script>

<style lang="scss">
.expanded-container {
  position: absolute;
  width: 82%;
  height: 100vh;
  background: #fff;
  z-index: 4;
  color: #484948;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5%;
  right: 0;
}
.expanded-wrapper {
  position: relative;
  height: fit-content;
  height: 100%;
  padding: 16px 0;
}
.expanded-top {
  height: 169px;
  top: 15px;
  right: 0;
  padding: 0 16px;
  box-sizing: border-box;
}
.expanded-bottom {
  position: relative;
  height: calc(100vh - 169px);
  overflow-y: scroll;
  box-sizing: border-box;
  overflow-wrap: break-word;
}
.btn-24 {
  width: 24px;
  height: 24px;
}
.user-info-text {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5%;
  margin: auto;
}
.user-icon-mar {
  margin: 0 8px 0 0;
}
.button-group {
  padding: 16px 0 32px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #f1f1f1;
}
.btn-common {
  width: 100%;
  height: 40px;
  text-align: center;
}
.btn-admin {
  background: #54c7a2;
  color: #fff;
  border-radius: 2px;
}
.btn-half-left {
  width: 50%;
  height: 40px;
  margin: 16px 0 0 0;
  background: #f7f9fc;
  color: #8d8d8d;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.btn-half-left:after {
  content: "";
  display: block;
  border-right: 1px solid #dcdcdc;
  height: 12px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.btn-half-right {
  width: 50%;
  height: 40px;
  margin: 16px 0 0 0;
  background: #f7f9fc;
  color: #8d8d8d;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.btn-start {
  background: #54c7a2;
  color: #fff;
  border-radius: 2px;
  margin: 0 0 12px 0;
}
.btn-login-text {
  color: #309574;
}
.btn-login {
  background: #fff;
  border: 1px solid #54c7a2;
  text-align: center;
  box-sizing: border-box;
  border-radius: 2px;
}
.btn-function-text {
  color: #8d8d8d;
}
.exp-text-common {
  margin: auto auto;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
  overflow: hidden;
  @media (min-width: 480px) and (max-width: 767px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
    overflow: hidden;
  }
  @media (min-width: 768px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 585px;
    overflow: hidden;
  }
}
</style>
