var render = function () {
  var _vm$getLoggedData$pri;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'floating-outer': _vm.getFloatingType === 'list',
      'clear-dim': _vm.getFloatingType === 'userInfo' || _vm.getFloatingType === 'select' || _vm.getFloatingType === 'terms-select'
    },
    on: {
      "mouseover": function mouseover($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.mouseOverEvent.apply(null, arguments);
      },
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.clickEvent.apply(null, arguments);
      }
    }
  }, [_c('div', {
    ref: "floating",
    class: {
      'floating-container': _vm.getFloatingType !== 'terms-select',
      'floating-container-terms': _vm.getFloatingType === 'terms-select'
    },
    style: _vm.getFloatingPosition
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getFloatingType === 'list' || _vm.getFloatingType === 'select' || _vm.getFloatingType === 'terms-select',
      expression: "\n        getFloatingType === 'list' ||\n        getFloatingType === 'select' ||\n        getFloatingType === 'terms-select'\n      "
    }],
    staticClass: "floating-wrapper",
    class: {
      'floating-wrapper': _vm.getFloatingType !== 'terms-select',
      'floating-wrapper-terms': _vm.getFloatingType === 'terms-select'
    },
    on: {
      "mouseleave": _vm.mouseLeaveEvent
    }
  }, _vm._l(_vm.getFloatingData, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "cursor d-flex df-row",
      class: {
        'floating-each': _vm.getFloatingType !== 'terms-select',
        'floating-each-terms': _vm.getFloatingType === 'terms-select'
      },
      on: {
        "mouseover": function mouseover($event) {
          return _vm.$set(_vm.hover, index, true);
        },
        "mouseleave": function mouseleave($event) {
          return _vm.$set(_vm.hover, index, false);
        },
        "click": function click($event) {
          $event.preventDefault();
          return _vm.beforeItemEvent(data);
        }
      }
    }, [data.icon ? _c('menu-icon', {
      attrs: {
        "name": data.icon,
        "width": 16,
        "height": 16,
        "fill": [_vm.hover[index] ? '#fff' : '#8d8d8d'],
        "styles": 'margin: 0 8px 0 0'
      }
    }) : _vm._e(), _c('p', {
      staticClass: "each-text"
    }, [_vm._v(_vm._s(data.name))])], 1);
  }), 0), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getFloatingType === 'userInfo',
      expression: "getFloatingType === 'userInfo'"
    }],
    staticClass: "floating-wrapper2 d-flex df-col"
  }, [_c('div', {
    staticClass: "d-flex df-row"
  }, [_c('div', {
    staticClass: "spacer"
  }), _c('img', {
    staticClass: "cursor",
    attrs: {
      "src": require('@/assets/images/introduction/btn_close.svg')
    },
    on: {
      "click": _vm.close
    }
  })]), _c('div', {
    staticClass: "d-flex df-col floating-info-area"
  }, [_vm.getLoggedData.grade === 0 ? _c('p', [_vm._v(" " + _vm._s("준회원(" + _vm.getLoggedData.id + ")") + " ")]) : _c('p', [_vm._v(" " + _vm._s((_vm$getLoggedData$pri = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri === void 0 ? void 0 : _vm$getLoggedData$pri.email) + " ")]), _c('div', {
    staticClass: "divider"
  }), _vm.getLoggedData.grade === 0 ? _c('div', {
    staticClass: "d-flex df-row item cursor",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/gradeup');
      }
    }
  }, [_c('img', {
    staticClass: "each-logo",
    attrs: {
      "src": require('@/assets/images/introduction/ico_member_change.svg')
    }
  }), _c('p', {
    staticClass: "each-text"
  }, [_vm._v("회원전환")])]) : _c('div', {
    staticClass: "d-flex df-row item cursor",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/personal');
      }
    }
  }, [_c('img', {
    staticClass: "each-logo",
    attrs: {
      "src": require('@/assets/images/introduction/ico_account_info.svg')
    }
  }), _c('p', {
    staticClass: "each-text"
  }, [_vm._v("계정정보")])]), _c('div', {
    staticClass: "d-flex df-row item cursor",
    on: {
      "click": _vm.userLogout
    }
  }, [_c('img', {
    staticClass: "each-logo",
    attrs: {
      "src": require('@/assets/images/introduction/ico_logout.svg')
    }
  }), _c('p', {
    staticClass: "each-text"
  }, [_vm._v("로그아웃")])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }