<template>
  <div
    :class="{
      'floating-outer': getFloatingType === 'list',
      'clear-dim':
        getFloatingType === 'userInfo' ||
        getFloatingType === 'select' ||
        getFloatingType === 'terms-select'
    }"
    @mouseover.self="mouseOverEvent"
    @click.self="clickEvent"
  >
    <div
      :class="{
        'floating-container': getFloatingType !== 'terms-select',
        'floating-container-terms': getFloatingType === 'terms-select'
      }"
      :style="getFloatingPosition"
      ref="floating"
    >
      <div
        class="floating-wrapper"
        :class="{
          'floating-wrapper': getFloatingType !== 'terms-select',
          'floating-wrapper-terms': getFloatingType === 'terms-select'
        }"
        v-show="
          getFloatingType === 'list' ||
          getFloatingType === 'select' ||
          getFloatingType === 'terms-select'
        "
        @mouseleave="mouseLeaveEvent"
      >
        <div
          v-for="(data, index) in getFloatingData"
          :key="index"
          class="cursor d-flex df-row"
          :class="{
            'floating-each': getFloatingType !== 'terms-select',
            'floating-each-terms': getFloatingType === 'terms-select'
          }"
          @mouseover="$set(hover, index, true)"
          @mouseleave="$set(hover, index, false)"
          @click.prevent="beforeItemEvent(data)"
        >
          <menu-icon
            v-if="data.icon"
            :name="data.icon"
            :width="16"
            :height="16"
            :fill="[hover[index] ? '#fff' : '#8d8d8d']"
            :styles="'margin: 0 8px 0 0'"
          />
          <p class="each-text">{{ data.name }}</p>
        </div>
      </div>
      <div
        class="floating-wrapper2 d-flex df-col"
        v-show="getFloatingType === 'userInfo'"
      >
        <div class="d-flex df-row">
          <div class="spacer"></div>
          <img
            :src="require('@/assets/images/introduction/btn_close.svg')"
            class="cursor"
            @click="close"
          />
        </div>
        <div class="d-flex df-col floating-info-area">
          <p v-if="getLoggedData.grade === 0">
            {{ "준회원(" + getLoggedData.id + ")" }}
          </p>
          <p v-else>
            {{ getLoggedData.privacy?.email }}
          </p>
          <div class="divider"></div>
          <div
            v-if="getLoggedData.grade === 0"
            class="d-flex df-row item cursor"
            @click="movePage('/accounts/gradeup')"
          >
            <img
              :src="
                require('@/assets/images/introduction/ico_member_change.svg')
              "
              class="each-logo"
            />
            <p class="each-text">회원전환</p>
          </div>
          <div
            v-else
            class="d-flex df-row item cursor"
            @click="movePage('/accounts/personal')"
          >
            <img
              :src="
                require('@/assets/images/introduction/ico_account_info.svg')
              "
              class="each-logo"
            />
            <p class="each-text">계정정보</p>
          </div>
          <div class="d-flex df-row item cursor" @click="userLogout">
            <img
              :src="require('@/assets/images/introduction/ico_logout.svg')"
              class="each-logo"
            />
            <p class="each-text">로그아웃</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {removeAuthToken} from "@/utils/auth";
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
import menuIcon from "@/components/svg/menu-icon";

export default {
  data() {
    return {
      hover: [],
      callback: null,
      updatedOnce: true
    };
  },
  computed: {
    newList() {
      return this.getFloatingData;
    },
    ...ModuleIntroCommon.mapGetters([
      "getFloatingShowState",
      "getFloatingData",
      "getFloatingPosition",
      "getFloatingType",
      "getRedirectPage"
    ]),
    ...ModuleAuth.mapGetters(["getLoggedData", "getLoginStatus"])
  },
  watch: {
    newList() {
      this.hover = [];
      this.getFloatingData.forEach((item, index) => {
        this.$set(this.hover, index, false);
      });
    },
    getFloatingShowState(val) {
      if (val && this.getFloatingType === "userInfo") {
        const _width = this.$refs.floating.offsetWidth;

        this.setFloatingPosition({
          top: this.getFloatingPosition.top + "px",
          left: this.getFloatingPosition.left - _width + "px"
        });
      }
      //업데이트 상태값 닫힐때 초기화
      if (!val) this.updatedOnce = true;
    }
  },
  created() {
    this.$EventBus.$on("alertCancel", event => {
      if (event === "login" && this.callback) {
        this.$router.push(
          "/accounts/login?callback=" + encodeURIComponent(this.callback)
        );
      }
    });
  },
  updated() {
    if (this.updatedOnce && this.getFloatingShowState) {
      const target = this.$refs.floating;
      const iHeight = window.innerHeight;
      const {bottom} = target.getBoundingClientRect();

      //컨텐츠 영역 바텀값이 브라우저 높이보다 큰 경우
      if (bottom > iHeight) {
        this.setFloatingPosition({
          top: this.getFloatingPosition.top + "px",
          left: this.getFloatingPosition.left + "px",
          width: this.getFloatingPosition.width + "px",
          "max-height": bottom - iHeight + "px",
          "overflow-y": "auto"
        });
      }
      this.updatedOnce = false;
    }
  },
  methods: {
    //항목 클릭 이벤트 전, 예외처리
    beforeItemEvent(data) {
      //1:1문의 선택시
      if (data.id === 407 && !this.getLoginStatus) {
        this.callback = data.clickTarget;
        this.setAlert({
          isVisible: true,
          message: "1:1문의는 로그인 후 이용할 수 있습니다.",
          event: "login"
        });
      } else {
        this.itemEvent(data);
      }
    },
    //항목 클릭 이벤트
    itemEvent(data) {
      switch (data.clickType) {
        case "window":
          window.open(data.clickTarget);
          break;

        case "href":
          location.href = data.clickTarget;
          break;

        case "push":
          this.$router.push(data.clickTarget);
          break;

        case "replace":
          this.$router.replace(data.clickTarget);
          break;

        case "data":
          this.$router.replace({
            query: {
              id: data.id ?? this.$route.query.id,
              fileName: data.fileName ?? this.$route.query.fileName
            }
          });
          break;

        case "temp":
          this.setTemp(data);
          break;
      }
      this.close();
    },
    // 로그아웃
    userLogout() {
      this.actLogout().then(() => {
        removeAuthToken();
        localStorage.removeItem("userId");
        this.setLogged({});
        this.setLoginStatus(false);
        this.close();

        //테마목록 페이지 내 로그아웃시 쿼리 스트링 삭제 (어드민 진입 상태값 삭제)
        if (this.$route.path === "/introduction/theme-list") {
          this.$router.replace({
            query: {
              id: this.$route.query.id
            }
          });
        }
      });
    },
    //페이지 이동
    movePage(path) {
      if (path === "/accounts/gradeup" || path === "/accounts/personal") {
        let routeData = this.$router.resolve({
          path: path,
          query: {
            callback: document.URL
          }
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push(path);
      }
      this.close();
    },
    mouseOverEvent() {
      if (
        this.getFloatingType === "userInfo" ||
        this.getFloatingType === "select" ||
        this.getFloatingType === "terms-select"
      )
        return false;
      this.close();
    },
    clickEvent() {
      if (this.getFloatingType === "list") return false;
      this.close();
    },
    mouseLeaveEvent() {
      if (
        this.getFloatingType === "select" ||
        this.getFloatingType === "terms-select"
      )
        return false;
      this.setFloatingShowState(false);
    },
    close() {
      this.setFloatingShowState(false);
      this.setFloatingPosition({});
      this.setFloatingData([]);
      this.setFloatingType("userInfo");
    },
    ...ModuleAuth.mapActions(["actLogout"]),
    ...ModuleAuth.mapMutations(["setLogged", "setLoginStatus"]),
    ...ModuleIntroCommon.mapMutations([
      "setFloatingShowState",
      "setFloatingData",
      "setFloatingPosition",
      "setFloatingType",
      "setRedirectPage",
      "setTemp"
    ]),
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  components: {
    menuIcon
  }
};
</script>

<style lang="scss">
.floating-outer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  padding: 0;
  margin: 0;
  z-index: 3;
}
.floating-container {
  position: absolute;
  width: fit-content;
  height: fit-content;
  background: #fff;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 4;
  color: #484948;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5%;
}
.floating-container-terms {
  position: absolute;
  width: fit-content;
  height: fit-content;
  background: #fff;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 4;
  color: #484948;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.5%;
}
.floating-wrapper {
  padding: 16px 0;
}
.floating-wrapper-terms {
  padding: 0;
}
.floating-each {
  padding: 12px 23px;
  background: #fff;
  vertical-align: center;
}
.floating-each:hover {
  background: #54c7a2;
  color: #fff;
}
.floating-each-terms {
  padding: 10px 16px;
  background: #fff;
  vertical-align: center;
  color: #8d8d8d;
  font-weight: 400;
}
.floating-each-terms:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.floating-each-terms:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.floating-each-terms:hover {
  background: #f0f0f0;
  color: #8d8d8d;
}
.each-logo {
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
}
.each-text {
  margin: auto 0;
}
.floating-wrapper2 {
  padding: 10px 12px 12px 12px;
  min-width: 220px;
}
.floating-info-area {
  padding: 22px 12px 0 12px;
  box-sizing: border-box;
}
.divider {
  width: 100%;
  margin: 16px 0 12px 0;
  border-bottom: 1px solid #dcdcdc;
}
.item {
  padding: 12px 0;
  box-sizing: border-box;
}
</style>
