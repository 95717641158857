<template>
  <div class="drawer-container d-flex df-col">
    <div
      class="drawer-wrapper cursor"
      v-for="(list, index) in data"
      :key="index"
      @click="changeMenu(index)"
    >
      <!-- 타이틀, 제어버튼 영역 -->
      <div class="d-flex df-row">
        <div class="drawer-title">{{ list.name }}</div>
        <div class="spacer"></div>
        <img
          v-show="list.path"
          :src="require('@/assets/images/introduction/btn_arrow.svg')"
        />
        <img
          :src="require('@/assets/images/introduction/btn_minus.svg')"
          v-show="list.children && openState[index]"
        />
        <img
          :src="require('@/assets/images/introduction/btn_plus.svg')"
          v-show="list.children && !openState[index]"
        />
      </div>
      <!-- 내용 영역 -->
      <div class="d-flex df-col" v-show="list.children && openState[index]">
        <div
          class="d-flex df-row drawer-content"
          v-for="(child, ix) in list.children"
          :key="ix"
        >
          <div class="d-flex df-row" @click.stop="changeChildMenu(index, ix)">
            <menu-icon
              :name="child.icon"
              :width="16"
              :height="16"
              :fill="[
                getCurrentMenuInfo.id === child.id ? '#54c7a2' : '#8d8d8d'
              ]"
              :style="'margin: 0 8px 0 0'"
            />
            <p
              class="child-text-margin"
              :class="{'menu-active': getCurrentMenuInfo.id === child.id}"
            >
              {{ child.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuIcon from "@/components/svg/menu-icon";
import {createNamespacedHelpers} from "vuex";
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  data() {
    return {
      openState: [],
      callback: null
    };
  },
  props: {
    data: {
      require: true
    }
  },
  computed: {
    newList() {
      return this.data;
    },
    ...ModuleIntroCommon.mapGetters(["getCurrentMenuInfo"]),
    ...ModuleAuth.mapGetters(["getLoginStatus"])
  },
  watch: {
    newList() {
      this.openState = [];
      this.data.forEach((item, index) => {
        this.$set(this.openState, index, false);
      });
    }
  },
  created() {
    this.$EventBus.$on("alertCancel", event => {
      if (event === "login" && this.callback) {
        this.$router.push(
          "/accounts/login?callback=" + encodeURIComponent(this.callback)
        );
      }
    });
  },
  methods: {
    //메뉴선택
    changeMenu(index) {
      const info = this.data[index];

      if (info.children) {
        this.$set(
          this.openState,
          index,
          (this.openState[index] = !this.openState[index])
        );
      } else {
        this.setCurrentMenuInfo(info);
        this.$emit("close");
        this.$router.push(info.path);
      }
    },
    //2depth 메뉴 선택
    changeChildMenu(index, childIndex) {
      const info = this.data[index];
      const child = info.children[childIndex];

      if (!info.children) return;
      //헬프센터 메뉴 선택시
      if (child.id >= 400) {
        this.beforeItemEvent(child);
      } else {
        const path = child.path || child.clickTarget;
        this.setCurrentMenuInfo(child);
        this.$router.push(path);
      }
      this.$emit("close");
    },
    //항목 클릭 이벤트 전, 예외처리
    beforeItemEvent(data) {
      //1:1문의 선택시
      if (data.id === 407 && !this.getLoginStatus) {
        this.callback = data.clickTarget;
        this.setAlert({
          isVisible: true,
          message: "1:1문의는 로그인 후 이용할 수 있습니다.",
          event: "login"
        });
      } else {
        this.itemEvent(data);
      }
    },
    //항목 클릭 이벤트
    itemEvent(data) {
      switch (data.clickType) {
        case "window":
          window.open(data.clickTarget);
          break;

        case "href":
          location.href = data.clickTarget;
          break;

        case "push":
          this.$router.push(data.clickTarget);
          break;

        case "replace":
          this.$router.replace(data.clickTarget);
          break;

        case "data":
          this.$router.replace({
            query: {
              id: data.id
            }
          });
          break;
      }
    },
    ...ModuleIntroCommon.mapMutations(["setCurrentMenuInfo"]),
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  components: {
    menuIcon
  }
};
</script>

<style lang="scss">
.menu-active {
  color: #54c7a2;
}
.drawer-container {
  width: 100%;
}
.drawer-wrapper {
  padding: 32px 0;
  margin: 0 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #f1f1f1;
}
.drawer-title {
  color: #8d8d8d;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.5%;
}
.drawer-content {
  color: #484948;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.5%;
  padding: 24px 2px 0 2px;
}
.child-icon {
  margin: 0 8px 0 0;
}
.child-text-margin {
  margin: auto 0;
}
</style>
