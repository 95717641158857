var render = function () {
  var _vm$getLoggedData$pri;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "expanded-container d-flex df-col"
  }, [_c('div', {
    staticClass: "expanded-wrapper"
  }, [_c('div', {
    staticClass: "expanded-top"
  }, [_c('div', {
    staticClass: "d-flex df-row"
  }, [_vm.loginStatus && _vm.getLoggedData.grade !== 0 ? _c('div', {
    staticClass: "d-flex df-row"
  }, [_c('img', {
    staticClass: "cursor btn-24 user-icon-mar",
    attrs: {
      "src": require('@/assets/images/introduction/ico_regular_user.svg')
    }
  }), _c('p', {
    staticClass: "user-info-text ellipsis"
  }, [_vm._v(" " + _vm._s((_vm$getLoggedData$pri = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri === void 0 ? void 0 : _vm$getLoggedData$pri.email) + " ")])]) : _vm.loginStatus && _vm.getLoggedData.grade === 0 ? _c('div', {
    staticClass: "d-flex df-row"
  }, [_c('img', {
    staticClass: "cursor btn-24 user-icon-mar",
    attrs: {
      "src": require('@/assets/images/introduction/ico_associate_user.svg')
    }
  }), _c('p', {
    staticClass: "user-info-text ellipsis"
  }, [_vm._v(" " + _vm._s("준회원(" + _vm.getLoggedData.id + ")") + " ")])]) : _vm._e(), _c('div', {
    staticClass: "spacer"
  }), _c('img', {
    staticClass: "cursor btn-24",
    attrs: {
      "src": require('@/assets/images/introduction/btn_close.svg')
    },
    on: {
      "click": _vm.close
    }
  })]), _c('div', {
    staticClass: "d-flex df-col button-group"
  }, [_vm.loginStatus ? _c('div', [_c('div', {
    staticClass: "btn-common btn-admin d-flex cursor",
    on: {
      "click": function click($event) {
        return _vm.movePage('/admin/dashboard');
      }
    }
  }, [_c('p', {
    staticClass: "exp-text-common centered"
  }, [_vm._v("나의 테마관리")])]), _c('div', {
    staticClass: "d-flex df-row"
  }, [_c('div', {
    staticClass: "btn-common btn-half-left d-flex cursor",
    on: {
      "click": function click($event) {
        return _vm.movePage(_vm.getLoggedData.grade === 0 ? '/accounts/gradeup' : '/accounts/personal');
      }
    }
  }, [_vm.getLoggedData.grade === 0 ? _c('p', {
    staticClass: "exp-text-common btn-function-text centered"
  }, [_vm._v(" 회원전환 ")]) : _c('p', {
    staticClass: "exp-text-common centered cursor btn-function-text"
  }, [_vm._v(" 계정정보 ")])]), _c('div', {
    staticClass: "btn-common btn-half-right d-flex cursor",
    on: {
      "click": _vm.userLogout
    }
  }, [_c('p', {
    staticClass: "exp-text-common btn-function-text"
  }, [_vm._v("로그아웃")])])])]) : _c('div', [_c('div', {
    staticClass: "btn-common btn-start d-flex cursor",
    on: {
      "click": function click($event) {
        return _vm.movePage('/accounts/signup');
      }
    }
  }, [_c('p', {
    staticClass: "exp-text-common centered"
  }, [_vm._v("무료 시작하기")])]), _c('div', {
    staticClass: "btn-common btn-login d-flex cursor",
    on: {
      "click": function click($event) {
        return _vm.beforeLoginPageLoad('/accounts/login');
      }
    }
  }, [_c('p', {
    staticClass: "exp-text-common centered btn-login-text"
  }, [_vm._v("로그인")])])])])]), _c('div', {
    staticClass: "expanded-bottom"
  }, [_c('div', {
    staticClass: "d-flex df-col"
  }, [_c('expanded-drawer', {
    attrs: {
      "data": _vm.getExpandedData
    },
    on: {
      "close": _vm.close
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }