var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('iheader', {
    on: {
      "movePage": _vm.movePage
    }
  }), _c('router-view', {
    on: {
      "movePage": _vm.movePage
    }
  }), _c('ifooter', {
    on: {
      "movePage": _vm.movePage
    }
  }), _c('main-notice-popup', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.noticeState,
      expression: "noticeState"
    }],
    on: {
      "noticeClose": _vm.noticeClose
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.mobileSlideState,
      expression: "mobileSlideState"
    }],
    staticClass: "dim",
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('expanded-menu', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getExpandedShowState,
      expression: "getExpandedShowState"
    }],
    on: {
      "close": _vm.close
    }
  })], 1)], 1), _c('full-dim-popup', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getFullDimShowState,
      expression: "getFullDimShowState"
    }]
  }), _c('floating-menu', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getFloatingShowState,
      expression: "getFloatingShowState"
    }]
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }